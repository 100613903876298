<mat-toolbar color="primary" *ngIf="user?.UserRole?.UserRoleId">
    <div fxFlex fxLayout="row" class="toolbar" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" class="CompanyName d-none d-md-block">
            <img src="assets/images/logo.png" (click)="showCoreModule('normal')" style="cursor: pointer;">
            <span>{{Company.CompanyName}}</span>
        </div>

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!-- <button mat-icon-button class="d-md-block" (click)="openFullscreen()"><mat-icon>fullscreen</mat-icon></button> -->
            <!-- <button mat-icon-button class="d-md-block" (click)="closeFullscreen()"><mat-icon>fullscreen_exit</mat-icon></button> -->
            <button mat-icon-button class="d-none d-md-block">
                <mat-icon>mail_outline</mat-icon>
            </button>
            <button class="setting-button" mat-icon-button [matMenuTriggerFor]="notification" (click)="alertsList(1)">
                <mat-icon>notifications_none</mat-icon>
                <span class="badge" *ngIf="alertList?.Count > 0 && alertList?.Count < 10">{{alertList?.Count}}</span>
                <span class="badge" *ngIf="alertList?.Count >= 10">9+</span>
            </button>
            <mat-menu #notification="matMenu" [overlapTrigger]="false" xPosition="before">
                <div *ngIf="alertList.NotificationList?.length > 0">
                    <a class="mb-3 dropdown-item"
                        *ngFor="let alert of alertList.NotificationList | slice:0:4; let i=index">
                        <small class="pl-2 mt-1 text-muted sideTime">{{alert.TimeAgo}}</small>
                        <div class="media d-flex">
                            <div class="SideImg">
                                <img class="avatar" [src]="alert.AssignedToImageM?.ImageFilePath">
                            </div>
                            <div class="media-body align-self-center ml-2 text-truncate">
                                <h5 class="my-0 font-weight-normal text-dark">{{alert.Description}}</h5>
                                <small class="text-muted mb-0">{{alert.BusinessProcess?.BusinessProcessName}}</small>
                            </div>
                        </div>
                    </a>
                    <a class="dropdown-item more_view" [routerLink]="['app/notification']">
                        <b>View All</b>
                    </a>
                </div>
                <div *ngIf="alertList.NotificationList?.length == 0" style="text-align: center; color: #4c4c4c;">
                    <span class="material-icons">notification_important</span>
                    <p style="margin:0 12px;">You don't have any Notification</p>
                </div>
            </mat-menu>

            <button mat-icon-button class="d-none d-md-block">
                <mat-icon>error_outline</mat-icon>
            </button>

            <!-- <button mat-icon-button *ngIf="!configure">
                <mat-icon>settings</mat-icon>
            </button> -->
            <button mat-icon-button [matMenuTriggerFor]="settingsMenu" *ngIf="configure > -1">
                <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #settingsMenu="matMenu" xPosition="before" class="designMenu">
                <button mat-menu-item (click)="showCoreModule('setting')">Configuration</button>
                <!-- <button mat-menu-item routerLink="/app/toolbar" *ngIf="isTool > -1">Tools</button>
                <button mat-menu-item routerLink="/app/attendence-log"  *ngIf="isAttendenceLog > -1">Attendence Log</button> -->
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="appLinkMenu" class="d-none d-md-block">
                <mat-icon>apps</mat-icon>
            </button>
            <mat-menu #appLinkMenu="matMenu" xPosition="before" class="applink-menu">
                <div class="d-flex flex-wrap">
                    <div class="col-md-4 d-flex">
                        <!-- http://localhost:4200/dashboard -->
                        <!-- https://dmsdev.vertexplus.com/dashboard -->
                        <button type="button" mat-menu-item (click)="dms(dmsUrl)">
                            <mat-icon>account_circle</mat-icon> DMS
                        </button>
                        <button type="button" mat-menu-item (click)="Project(projectsUrl)">
                          <mat-icon>local_activity</mat-icon> Project
                      </button>
                    </div>
                </div>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="logoutMenu" class="userBtn">
                <div class="userDrop">
                    <span class="userImage"><img src="{{user?.EmaployeeImagePath}}"
                            onerror="this.src='assets/images/user_b.png'"></span>
                    <span class="userName d-none d-md-block">{{user?.UserName}}
                        <h5 class="userRole">{{user?.UserRole?.UserRoleName}}</h5>
                    </span>
                    <i class="material-icons">keyboard_arrow_down</i>
                </div>
            </button>
            <mat-menu #logoutMenu="matMenu" xPosition="before" class="designMenu">
                <!-- <button mat-menu-item *ngFor="let list of userAllLinks" (click)="userSigLink(list.SiteURL)">
                    <mat-icon>person</mat-icon> {{list.ModuleName}}
                </button>
                <button mat-menu-item>
                    <mat-icon>manage_accounts</mat-icon> Eppika
                </button>
             [class.logout-menu]="userAllLinks && userAllLinks?.length > 0"-->
                <div *ngFor="let list of user.UserRoleList; let i = index">
                    <span mat-menu-item
                        (click)="changeUserRole(list.UserRoleId, user.UserRoleList)">{{list.UserRoleName}}</span>
                </div>
                <button mat-menu-item class="text-primary" [routerLink]="['app/profile']">
                    Profile
                </button>
                <button mat-menu-item class="text-primary" (click)="changepassword()">
                    Change Password
                </button>
                <button mat-menu-item (click)="logout()" class="text-primary">
                    <!-- <mat-icon>exit_to_app</mat-icon> -->
                    Log Out
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
