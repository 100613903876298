import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../core/masterservice/service.service';
import { MatDialog } from '@angular/material/dialog';
import { ReasonModalComponent } from 'app/login/reason-modal/reason-modal.component';
import { CommonModalComponent } from '../core/common/common-modal/common-modal.component';
import { DataStorage } from 'app/store/storage';
import { AddContactModalComponent } from '../lead/campaign-enquiries/add-contact-modal/add-contact-modal.component';
import { planworkConfig } from 'app/planwork.config';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  normalView: boolean;
  currentUrl: string;
  user: any;
  iview: boolean;
  alertList: any = {};
  Company: any;
  elem;

  // isTool: any;
  // isAttendenceLog: any;
  // data: any =  {"IPAddress":"111.93.59.126:","TimeZone":"+05:30","LoginId":5,"IsActive":true};
  dynamicUrl: any;
  apiUrl: any;
  userAllLinks: any[] = [];
  configure: any;
  hrms: any;
  logoutInput: any = { Company: {} };
  dialogRef: any;
  location: any;
  tokendataHrms: any;
  dmsUrl: any;
  projectsUrl:any;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private planworkConfigdata: PlanworkConfigService,
    private dashboardService: ServiceService,
    private store: DataStorage,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: any) {
    this.user = JSON.parse(localStorage.getItem('userid'));

    this.apiUrl = planworkConfig;
    if (this.apiUrl.baseUrl == 'https://sdmapibeta.vertexplus.com/' || this.apiUrl.baseUrl == 'http://sdmapibeta.vertexplus.com/') {
      this.dynamicUrl = "http://payrollbeta.vertexplus.com/LandingPage.aspx";
      this.dmsUrl = "https://dmsdev.vertexplus.com/dashboard";
      this.projectsUrl = "https://syncbeta.vertexplus.com/projects/apps/dashboards";
    } else {
      this.dynamicUrl = "http://payroll.vertexplus.com/LandingPage.aspx"
      this.dmsUrl = "https://dms.vertexplus.com/dashboard";
      this.projectsUrl = "https://syncbeta.vertexplus.com/projects/apps/dashboards";
    }
  }

  async ngOnInit() {
    console.log(document)
    this.elem = document.documentElement;
    if (this.user) {
      // this.getHrmsToken()
    }
    this.Company = JSON.parse(localStorage.getItem('Company'));
    const tokenData = JSON.parse(localStorage.getItem('userid'));
    if (tokenData) {
      // const primaryRole = tokenData.UserRoleList.filter(x => x.IsPrimaryRole === true)[0];
      const primaryRole = tokenData.UserRoleList.filter(x => x.UserRoleId == tokenData.UserRole.UserRoleId)[0];
      let master = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'configure')[0];
      let hrms = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'HRMS')[0];
      //  let tools = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'Tools')[0];
      //  let attendenceLog = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'attendence_log')[0];
      if (master) {
        this.configure = master.RoleId.filter(x => x === this.user.UserRole.UserRoleId)[0];
      }
      if (hrms) {
        this.hrms = master.RoleId.filter(x => x === this.user.UserRole.UserRoleId)[0];
      }
      // if(tools){
      //   this.isTool = tools.RoleId.filter(x => x === this.user.UserRole.UserRoleId)[0];
      // }
      // if(attendenceLog){
      //   this.isAttendenceLog = attendenceLog.RoleId.filter(x => x === this.user.UserRole.UserRoleId)[0];
      // }
    }
    await this.viewSet();
    if (localStorage.getItem('token')) {
      // await this.alertsList(0);
      this.userLinks();
    }
  }
  getHrmsToken() {
    this.dashboardService.postEmployee({}, 'GetEncryptedUserId')
      .subscribe(response => {
        this.tokendataHrms = response.body.Result;
      });
  }
  dms(url) {
    // debugger
    // window.location.href = url + '?p='+ this.user.Token
    window.open(
      url + '?p=' + this.user.Token,
      '_blank'
    );
  }
 Project(url) {
    // debugger
    // window.location.href = url + '?p='+ this.user.Token
    window.open(
      url + '?p=' + this.user.Token,
      '_blank'
    );
  }
  userLinks() {
    this.dashboardService.employeeCoreService({}, 'ModuleDropDownList?UserRoleId=' + this.user.UserRole.UserRoleId)
      .subscribe(response => {
        this.userAllLinks = response.body;
      });
  }
  showCoreModule(type) {
    this.planworkConfigdata.viewType.next(type);
    if (type == 'setting') {
      this.store._Masterpermission.subscribe(res => {
        let CheckUrl;


        for (let i = 0; i <= res.children.length; i++) {
          if (CheckUrl) {
            console.log(CheckUrl);
            this.router.navigate([CheckUrl.url])
            break;
          } else {
            CheckUrl = res.children[i].children.filter(x => x.type == true)[0];
          }
        }
      })
    }
    else {
      window.location.href = "";
    }
  }
  alertsList = async (val) => {
    if (val === 1) {
      this.iview = true
    } else {
      this.iview = false
    }
    this.dashboardService.DashboardService({ IsView: this.iview }, 'NotificationList').subscribe(response => {
      this.alertList = response.body.Result;
    });
  }
  async viewSet() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let iscommon = this.currentUrl.includes("common");
        let istoolbar = this.currentUrl.includes("toolbar");
        let isattendencelog = this.currentUrl.includes("attendence-log");
        this.planworkConfigdata.viewType.subscribe((view) => {
          if (!iscommon || !istoolbar || !isattendencelog) {
            if (view == 'normal') {
              this.normalView = true;
            }
            else {
              this.normalView = false;
            }
          }
        })

        if (iscommon || istoolbar || isattendencelog) {
          this.planworkConfigdata.viewType.next('setting');
        }
      }
    });
  }
  ngOnDestroy() {
    this.planworkConfigdata.viewType.unsubscribe();
  }
  logout() {
    // this.logoutInput.Company = {
    //   "CompanyId": 1,
    //   "CompanyName": "VertexPlus Technologies Private Limited",
    //   "CompanyCode": "VTPL03"
    // }
    // this.logoutInput.IPAddress = "111.93.59.126:";
    // this.logoutInput.TimeZone = "+05:30";
    // this.logoutInput.LoginId = this.user.LoginId;
    // this.logoutInput.IsActive = true;
    // this.logoutInput.Reason = 'Dummy Logout Reason';

    // this.dashboardService.LogoutService(this.logoutInput, 'Login/Logout')
    //   .subscribe(response => {
    //     if (response.body.Result.IsUserAccess && response.body.Result.IsUserActive) {
          localStorage.clear();
          this.router.navigate(['/login'])
            .then(() => {
              this.spinner.hide();
              this.user = {};
            });
        // } else if (!response.body.Result.IsUserAccess && response.body.Result.IsUserActive) {
        //   this.dialogRef = this.dialog.open(ReasonModalComponent, {
        //     panelClass: 'modal-sm',
        //     data: { action: 'LogoutReason' },
        //     autoFocus: false,
        //   });
        //   this.dialogRef.afterClosed()
        //     .subscribe(result => {
        //       if (!result) {
        //         return;
        //       } else {
        //         this.logoutInput.Reason = result.Reason;
        //         this.logout();
        //       }
        //     })
        // } else {
        //   this.dialogRef = this.dialog.open(CommonModalComponent, {
        //     width: '300px',
        //     disableClose: true,
        //     autoFocus: false,
        //     panelClass: 'statusClass',
        //     data: { action: 'Alert', statusText: response.body.Result.TransactionMessage.Message }
        //   });
        // }

        // this.spinner.show();
        // localStorage.clear();
        // this.router.navigate(['/login'])
        //   .then(() => {
        //     this.spinner.hide();
        //     this.user = {};
        //   });


      // });
  }
  changeUserRole(id, list) {
    let usercurrentRoles = JSON.parse(localStorage.getItem('userid'));
    //True Set
    for (let ch of usercurrentRoles.UserRoleList) {
      ch.IsPrimaryRole = false;
    }
    const a = usercurrentRoles.UserRoleList.filter(x => x.UserRoleId == id)[0];
    a.IsPrimaryRole = true;
    const currentRole = usercurrentRoles.UserRoleList.filter(x => x.UserRoleId === id)[0];
    usercurrentRoles.UserRole.ReportingAuthority = currentRole.ReportingAuthority;
    usercurrentRoles.UserRole.UserRoleId = currentRole.UserRoleId;
    usercurrentRoles.UserRole.UserRoleName = currentRole.UserRoleName;
    usercurrentRoles.UserRole.UserRoleName = currentRole.UserRoleName;
    localStorage.setItem('roleId', JSON.stringify(currentRole.UserRoleId));
    localStorage.setItem('userid', JSON.stringify(usercurrentRoles))
    window.location.reload();
  }
  changepassword() {
    this.dialogRef = this.dialog.open(AddContactModalComponent, {
      width: '435px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'changepassword',
      data: { action: 'changepassword', }

    })
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.dialogRef.close();

        this.logoutInput.Company = {
          "CompanyId": 1,
          "CompanyName": "VertexPlus Technologies Private Limited",
          "CompanyCode": "VTPL03"
        }
        this.logoutInput.IPAddress = "111.93.59.126:";
        this.logoutInput.TimeZone = "+05:30";
        this.logoutInput.LoginId = this.user.LoginId;
        this.logoutInput.IsActive = true;
        this.logoutInput.Reason = 'Logout due to password change';

        this.dashboardService.LogoutService(this.logoutInput, 'Login/Logout')
          .subscribe(response => {
            if (response.body.Result.IsUserAccess && response.body.Result.IsUserActive) {
              localStorage.clear();
              this.router.navigate(['/login'])
                .then(() => {
                  this.spinner.hide();
                  this.user = {};
                });
            }
          });
      }
    });

  }
  openFullscreen() {
    var evt = new CustomEvent("openFullScreen", {  });
  window.dispatchEvent(evt);
  }
}
